import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-modern-schooling-about',
    templateUrl: './modern-schooling-about.component.html',
    styleUrls: ['./modern-schooling-about.component.scss'],
    standalone: false
})
export class ModernSchoolingAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
