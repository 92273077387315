import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-free-trial',
    templateUrl: './free-trial.component.html',
    styleUrls: ['./free-trial.component.scss'],
    standalone: false
})
export class FreeTrialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
