import { Component } from '@angular/core';
import { MyApiService } from '../../my-api.service';
import { environment } from 'src/app/environment';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrl: './carousel.component.scss',
    standalone: false
})
export class CarouselComponent {
    data: any;
    constructor(private myApiService: MyApiService) {}

    ngOnInit(): void {
        // Call GET method

        this.myApiService.getData(null).subscribe(
            (response) => {
                if (response.data.sliderImages.length > 0) {
                    this.data = response.data.sliderImages;
                    this.data = this.data.map((img: any) => {
                        return {
                            path: `${environment.APP_URL}/${img.path}`,
                            name: img.name,
                            type: 'image',
                            size: img.size,
                        };
                    });
                } else {
                    this.data = [
                        {
                            path: 'assets/img/IMG-20240911-WA0003.jpg',
                            name: 'futureVision.png',
                            type: 'image',
                            size: '7916',
                        },
                        {
                            path: 'assets/img/IMG-20240911-WA0003.jpg',
                            name: 'futureVision.png',
                            type: 'image',
                            size: '7916',
                        },
                        {
                            path: 'assets/img/IMG-20240911-WA0003.jpg',
                            name: 'futureVision.png',
                            type: 'image',
                            size: '7916',
                        },
                    ];
                }
            },
            (error) => {
                console.error('Error fetching data', error);
            },
        );
    }
}
