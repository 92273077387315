import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-purchase-guide',
    templateUrl: './purchase-guide.component.html',
    styleUrls: ['./purchase-guide.component.scss'],
    standalone: false
})
export class PurchaseGuideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
