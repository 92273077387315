<!-- <div class="page-title-area">
    <div class="container"> -->
<div class="page-title-content">

    <h2>{{courseName}} (Previous Year QP)</h2>

</div>
<!-- </div>

</div>  -->

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <div class="courses-details-desc">
                    <ul class="nav-tabset">




                    </ul>
                    <div *ngIf="!courseList.length" class="empty-message">
                        <p>No data available.</p>
                    </div>
                    <div class="tabs-container">
                        <div class="pane" id="tab2">

                            <div class="courses-curriculum" *ngFor="let course of courseList">

                                <h3>{{course.name}} {{this.courseList.length}}</h3>
                                <ul *ngFor="let document of course.documents">
                                    <li>
                                        <a
                                            class="popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">{{document.title}}</span>
                                            <div class="courses-meta">

                                                <span class="status" (click)="openQP(document.url)"> View</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-5 widget-area">
                <div class="widget widget_categories fs-6">
                    <div class="fs-6 fw-bolder mb-4 widget-title">{{ courseName }}</div>
                    <ul>

                        <li>
                            <a [routerLink]="['/',courseName, 'sample-classes']" class="align-items-center"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Sample Class ({{ courseDetails?.videosCount ?? 0 }})
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/',courseName, 'sample-materials']" class="align-items-center"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Sample Materials ({{ courseDetails?.materialsCount ?? 0 }})
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/',courseName, 'sample-test']" class="align-items-center"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Sample Test ({{ courseDetails?.examsCount ?? 0 }})
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/',courseName, 'previous-year-qp']" class="align-items-center activate-route"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Previous Year QP({{ courseDetails?.preYearCount ?? 0
                                     }})
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/',courseName, 'syllabus']" class="align-items-center"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Syllabus ({{ courseDetails?.syllabusCount ?? 0}})
                            </a>
                        </li>

                    </ul>
                </div>
                <div class="widget widget_odemy_posts_thumb fs-6">
                    <div class="fs-6 fw-bolder mb-4 widget-title">Related Courses</div>
                    <div
                        *ngFor="let course of courseDetails?.relatedCourses"
                        class="fw-bold item"
                    >
                        <div *ngIf="course.category?.name" class="info">
                            <span>{{ course.category.name }}</span>
                        </div>
                        <div class="fw-bold fs-6">
                            <a
                                [routerLink]="[categoryName ? '/'+categoryName : '/', course.name, 'sample-classes']"
                                class="align-items-center"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                            >
                                {{ course.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
