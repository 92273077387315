import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-language-school-about',
    templateUrl: './language-school-about.component.html',
    styleUrls: ['./language-school-about.component.scss'],
    standalone: false
})
export class LanguageSchoolAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
