import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-courses-style-five',
    templateUrl: './courses-style-five.component.html',
    styleUrls: ['./courses-style-five.component.scss'],
    standalone: false
})
export class CoursesStyleFiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
