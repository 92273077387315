import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog-style-five',
    templateUrl: './blog-style-five.component.html',
    styleUrls: ['./blog-style-five.component.scss'],
    standalone: false
})
export class BlogStyleFiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
