import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-coaching-program',
    templateUrl: './coaching-program.component.html',
    styleUrls: ['./coaching-program.component.scss'],
    standalone: false
})
export class CoachingProgramComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
