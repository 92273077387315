import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-subscribe-style-five',
    templateUrl: './subscribe-style-five.component.html',
    styleUrls: ['./subscribe-style-five.component.scss'],
    standalone: false
})
export class SubscribeStyleFiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
