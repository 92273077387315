import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-view-all-courses-two',
    templateUrl: './view-all-courses-two.component.html',
    styleUrls: ['./view-all-courses-two.component.scss'],
    standalone: false
})
export class ViewAllCoursesTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
