import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog-style-four',
    templateUrl: './blog-style-four.component.html',
    styleUrls: ['./blog-style-four.component.scss'],
    standalone: false
})
export class BlogStyleFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
