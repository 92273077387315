import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-premium-access-two',
    templateUrl: './premium-access-two.component.html',
    styleUrls: ['./premium-access-two.component.scss'],
    standalone: false
})
export class PremiumAccessTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
