import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-download-book',
    templateUrl: './download-book.component.html',
    styleUrls: ['./download-book.component.scss'],
    standalone: false
})
export class DownloadBookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
