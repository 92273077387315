import { Component, Input } from '@angular/core';
import { ILatestUpdate } from '../home/home.interface';

@Component({
    selector: 'app-marquee',
    templateUrl: './marquee.component.html',
    styleUrl: './marquee.component.scss',
    standalone: false
})
export class MarqueeComponent {
    @Input() page = 1;

    @Input() latestUpdates: ILatestUpdate[];
    // Sample to-do list with news content
    todoList = [
        {
            news: 'Breaking News 1: Something amazing happened!',
            link: 'www.google.com',
        },
        { news: 'Breaking News 2: Another awesome event took place.' },
        { news: 'Breaking News 3: The weather today is sunny and warm.' },
        {
            news: 'Breaking News 4: Stock market reaches all-time high.',
            link: 'www.google.com',
        },
        { news: 'Breaking News 5: A new discovery in space exploration!' },
    ];

    stopScroll() {
        document.querySelector('.marquee-content')?.classList.add('paused');
    }

    startScroll() {
        document.querySelector('.marquee-content')?.classList.remove('paused');
    }
}
