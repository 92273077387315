import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog-details-style-two',
    templateUrl: './blog-details-style-two.component.html',
    styleUrls: ['./blog-details-style-two.component.scss'],
    standalone: false
})
export class BlogDetailsStyleTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
