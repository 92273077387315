import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-oa-banner',
    templateUrl: './oa-banner.component.html',
    styleUrls: ['./oa-banner.component.scss'],
    standalone: false
})
export class OaBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
