import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-gym-coaching',
    templateUrl: './gym-coaching.component.html',
    styleUrls: ['./gym-coaching.component.scss'],
    standalone: false
})
export class GymCoachingComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}