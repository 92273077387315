import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-how-to-apply',
    templateUrl: './how-to-apply.component.html',
    styleUrls: ['./how-to-apply.component.scss'],
    standalone: false
})
export class HowToApplyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
