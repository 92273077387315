import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-single-instructor',
    templateUrl: './single-instructor.component.html',
    styleUrls: ['./single-instructor.component.scss'],
    standalone: false
})
export class SingleInstructorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
