import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-courses-types',
    templateUrl: './courses-types.component.html',
    styleUrls: ['./courses-types.component.scss'],
    standalone: false
})
export class CoursesTypesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
