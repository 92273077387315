import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-oa-events',
    templateUrl: './oa-events.component.html',
    styleUrls: ['./oa-events.component.scss'],
    standalone: false
})
export class OaEventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
