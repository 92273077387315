import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-language-school-courses',
    templateUrl: './language-school-courses.component.html',
    styleUrls: ['./language-school-courses.component.scss'],
    standalone: false
})
export class LanguageSchoolCoursesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
