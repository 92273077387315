import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-online-platform',
    templateUrl: './online-platform.component.html',
    styleUrls: ['./online-platform.component.scss'],
    standalone: false
})
export class OnlinePlatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
