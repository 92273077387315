import { Component } from '@angular/core';
import { MyApiService } from '../../custom/my-api.service';

@Component({
    selector: 'app-terms-and-conditions',
    standalone: true,
    templateUrl: './terms-and-conditions.component.html',
    styleUrl: './terms-and-conditions.component.scss',
})
export class TermsAndConditionsComponent {
    content: string = '';

    constructor(private apiService: MyApiService) {
        this.apiService.getWebsiteData().subscribe(({ data }) => {
            this.content = data?.termsAndConditions ?? '';
        });
    }
}
