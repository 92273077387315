import {Component, OnDestroy, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-elearning-school',
    templateUrl: './elearning-school.component.html',
    styleUrls: ['./elearning-school.component.scss'],
    standalone: false
})
export class ElearningSchoolComponent implements OnInit, OnDestroy {

    constructor(private titleService: Title, private metaService: Meta) { }

    ngOnInit(): void {

        // Set the title for the page
        this.titleService.setTitle('Home – Welcome to Future Vision Study Centre');

        // Set the meta description for the page
        this.metaService.updateTag({ name: 'description', content: 'Welcome to Future Vision Study Centre' });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({ name: 'keywords', content: 'home, angular, seo, website' });
    }

    ngOnDestroy() {
        // Set the title for the page
        this.titleService.setTitle('Future Vision Study Centre');

        // Set the meta description for the page
        this.metaService.updateTag({ name: 'description', content: 'Welcome to Future Vision Study Centre' });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({ name: 'keywords', content: 'future vision, angular, seo, website' });
    }

}
