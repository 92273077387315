import { Component, OnInit } from '@angular/core';
import { MyApiService } from '../../my-api.service';
import { environment } from 'src/app/environment';
import { Router } from '@angular/router';
import { ILatestUpdate } from '../home.interface';

@Component({
    selector: 'app-coursehighlight',
    templateUrl: './coursehighlight.component.html',
    styleUrl: './coursehighlight.component.scss',
    standalone: false
})
export class CoursehighlightComponent implements OnInit {
    data: any;
    courses: any;
    latestUpdates: ILatestUpdate[];

    constructor(
        private myApiService: MyApiService,
        private router: Router,
    ) { }
    response: any = {
        statusCode: 200,
        status: true,
        message: 'Data Retrieved',
        data: {
            name: 'TNSPSC',
            attachment: {
                path: '\\mediaFiles\\futureVision\\version1\\website\\1721157931012_file_name-Screenshot2024-02-05 143155.png',

                name: 'Screenshot 2024-02-05 143155.png',
                type: 'image',
                size: '142440',
            },
            icon: {
                path: '\\mediaFiles\\futureVision\\version1\\website\\1721157931012_file_name-Screenshot2024-02-05 143155.png',
                name: 'Screenshot 2024-02-05 143155.png',
                type: 'image',
                size: '142440',
            },
            desc: 'for website courses',
            _id: '66ae895383f230fe20fb167c',
            courses: [
                {
                    _id: '631580c07f2ec6ef1ca5103d',
                    name: 'TNPSC : GR - 1, GR - 2 & GR - 4 PRELIMS - TAMIL MEDIUM',
                    type: {
                        _id: '63157ec37f2ec6ef1ca5101d',
                        name: 'Online',
                    },
                    attachment: null,
                },
                {
                    _id: '631593d27f2ec6ef1ca5113f',
                    name: 'TNPSC : GR - 1, GR - 2 & GR - 4 PRELIMS - ENGLISH MEDIUM',
                    type: {
                        _id: '63157ec37f2ec6ef1ca5101d',
                        name: 'Online',
                    },
                    attachment: {
                        name: 'fut3.JPG',
                        path: '/futureVision/version1/course/courses/1667620586537_file_name-fut3.JPG',
                        size: '92016',
                        type: 'jpeg',
                    },
                },
            ],
        },
    };

    ngOnInit(): void {
        this.myApiService.getAllLatestUpdates().subscribe((res) => {
            this.latestUpdates = res?.data?.list ?? [];
        });

        this.myApiService.getPopularCourseData().subscribe(
            (response) => {
                if (response.data.popularCourses.length > 0) {
                    this.data = response.data.popularCourses;
                    this.data = this.data.map(
                        ({
                            _id,
                            name,
                            category,
                            type: { name: courseType },
                            attachment,
                            fees = [],
                        }) => {
                            return {
                                _id,
                                name,
                                courseType,
                                fees,
                                categoryId: category ? category._id : '',
                                imgPath: attachment
                                    ? environment.APP_URL + attachment.path
                                    : 'assets/img/1371716613931.jpg',
                            };
                        },
                    );
                } else {
                    this.data = [
                        {
                            path: 'assets/img/IMG-20240911-WA0003.jpg',
                            name: 'futureVision.png',
                            type: 'image',
                            size: '7916',
                        },
                        {
                            path: 'assets/img/IMG-20240911-WA0003.jpg',
                            name: 'futureVision.png',
                            type: 'image',
                            size: '7916',
                        },
                        {
                            path: 'assets/img/IMG-20240911-WA0003.jpg',
                            name: 'futureVision.png',
                            type: 'image',
                            size: '7916',
                        },
                    ];
                }
            },
            (error) => {
                console.error('Error fetching data', error);
            },
        );
        this.myApiService.getCoursesData().subscribe((res: any) => {
            if (res?.error) {
                throw res.error;
            }
            if (res?.data?.data)
                this.courses = res?.data?.data.map((e) => {
                    return {
                        name: e.name,
                        path: `${environment.APP_URL}/${e?.icon.path}`,
                        id: e._id,
                    };
                });
        });
    }

    redirectCourses(id: string) {
        this.router.navigate([`/courses`], { queryParams: { id } });
    }
    redirectCourseCategory(id: string, name: string) {
        this.router.navigate([`/courses/${name}`]);
    }
    redirectToEnroll(id: string) {
        window.open(
            `${environment.APP_URL}//auth/sign-up?courseId=${id}`,
            '_blank',
        );
    }
    isModalVisible = false;
    courseName = '';
    fees = [];
    openModal(name, fees) {
        this.fees = fees;
        this.courseName = name;
        this.isModalVisible = true;
    }

    closeModal() {
        this.isModalVisible = false;
    }
}
