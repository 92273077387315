import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-gym-blog',
    templateUrl: './gym-blog.component.html',
    styleUrls: ['./gym-blog.component.scss'],
    standalone: false
})
export class GymBlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
