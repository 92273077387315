import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-get-started',
    templateUrl: './get-started.component.html',
    styleUrls: ['./get-started.component.scss'],
    standalone: false
})
export class GetStartedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
