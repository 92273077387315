import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-courses-style-two',
    templateUrl: './courses-style-two.component.html',
    styleUrls: ['./courses-style-two.component.scss'],
    standalone: false
})
export class CoursesStyleTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
