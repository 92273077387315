import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-online-training-courses',
    templateUrl: './online-training-courses.component.html',
    styleUrls: ['./online-training-courses.component.scss'],
    standalone: false
})
export class OnlineTrainingCoursesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
