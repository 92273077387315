import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-flexible-pricing',
    templateUrl: './flexible-pricing.component.html',
    styleUrls: ['./flexible-pricing.component.scss'],
    standalone: false
})
export class FlexiblePricingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
