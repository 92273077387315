import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-yoga-training',
    templateUrl: './yoga-training.component.html',
    styleUrls: ['./yoga-training.component.scss'],
    standalone: false
})
export class YogaTrainingComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

}