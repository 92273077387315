import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-become-a-teacher',
    templateUrl: './become-a-teacher.component.html',
    styleUrls: ['./become-a-teacher.component.scss'],
    standalone: false
})
export class BecomeATeacherComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
