import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-motivational-coaching-blog',
    templateUrl: './motivational-coaching-blog.component.html',
    styleUrls: ['./motivational-coaching-blog.component.scss'],
    standalone: false
})
export class MotivationalCoachingBlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
