import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-top-categories',
    templateUrl: './top-categories.component.html',
    styleUrls: ['./top-categories.component.scss'],
    standalone: false
})
export class TopCategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
