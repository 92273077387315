import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-subscribe-style-four',
    templateUrl: './subscribe-style-four.component.html',
    styleUrls: ['./subscribe-style-four.component.scss'],
    standalone: false
})
export class SubscribeStyleFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
