import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-boxes',
    templateUrl: './boxes.component.html',
    styleUrls: ['./boxes.component.scss'],
    standalone: false
})
export class BoxesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
