
import lgVideo from 'lightgallery/plugins/video';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MyApiService } from '../../my-api.service';

@Component({
    selector: 'app-previousyearqp',
    templateUrl: './previousyearqp.component.html',
    styleUrl: './previousyearqp.component.scss',
    standalone: false
})
export class PreviousyearqpComponent {
  courseName: any;
  courseList:any=[];
  categoryName:any;
  courseDetails: {
    examsCount: number;
    materialsCount: number;
    videosCount: number;
    syllabusCount:0;
    preYearCount:0;
    relatedCourses: {
        _id: string;
        name: string;
        category: { _id: string; name: string };
    }[];
};
  // courseList: any = [{ name: "boopathi" }];
  constructor(private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private myApiService: MyApiService,
    private titleService: Title,
    private metaService: Meta,) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.courseName = params.get('courseName');
      this.categoryName = params.get('categoryName');
      // alert(this.categoryName)
      // alert(this.courseName)
      if (this.courseName) {
        this.getCourseDetails();
        this.loadPageData();

        this.titleService.setTitle(
          `${this.courseName} - Sample Materials`,
        );

        // Set the meta description for the page
        this.metaService.updateTag({
          name: 'description',
          content: `${this.courseName} Sample Materials`,
        });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({
          name: 'keywords',
          content: 'sample-materials, angular, seo, website',
        });
      }
    });
  }

  private getCourseDetails() {
    this.courseDetails = undefined;
    this.myApiService
        .getCourseDetails(this.courseName)
        .subscribe(({ data = {} }) => {
            this.courseDetails = data;
            console.log(this.courseDetails)
        });
}

  loadPageData(): void {
    this.myApiService
      .getPreviousYearData({
           courseNames: [this.courseName]
      //   "courseNames": [
      //     "AO:AGRI"
      // ]
      })
      .subscribe((res: any) => {
        if (res?.error) {
          throw res.error;
        }
        if (res?.data) {
          if (res.data.list.length) {
            this.courseList = res.data.list;
          }
        }
      });
  }


  settings = {
    counter: false,
    plugins: [lgVideo]
  };
  // onBeforeSlide = (detail: BeforeSlideDetail): void => {
  //     const { index, prevIndex } = detail;
  //     console.log(index, prevIndex);
  // };

  // Tabs
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }
  openQP(url: string) {
    window.open(url, '_blank');
}
}
