import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-our-story',
    templateUrl: './our-story.component.html',
    styleUrls: ['./our-story.component.scss'],
    standalone: false
})
export class OurStoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
