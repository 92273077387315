<div class="container fs-3 fw-bolder mt-5 text-center">
    {{ courseName }} ({{ pageName.toUpperCase() }})
</div>

<div class="row">
    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <!-- Gallery Items -->
        <div class="gallery-area pb-70">
            <div class="container">
                <div *ngIf="!_album.length" class="empty-message">
                    <p>No videos available.</p>
                </div>
                <div class="row crousel-card-width" *ngIf="_album.length">
                    <div
                        class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pt-5 row"
                        *ngFor="let item of _album"
                    >
                        <div class="fs-4 fw-bolder mb-4">
                            {{ item.name === "-" ? "NONE" : item.name }}
                        </div>

                        <div class="services-slides">
                            <owl-carousel-o [options]="servicesSlides">
                                <ng-template
                                    *ngFor="
                                        let video of item.videos;
                                        let i = index
                                    "
                                    carouselSlide
                                >
                                    <div class="single-gallery-item">
                                        <!-- Thumbnail or iframe placeholder -->
                                        <img
                                            [src]="getThumbnailUrl(video.url)"
                                            (click)="openModal(video.url)"
                                        />
                                        <div>{{ video.name }}</div>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" [ngClass]="{ 'modal-open': isModalVisible }">
            <div class="modal-content">
                <span class="close" (click)="closeModal()">&times;</span>
                <iframe
                    [src]="videoUrl"
                    width="100%"
                    height="auto"
                    frameborder="0"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    </div>

    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-5 widget-area">
        <div class="widget widget_categories fs-6">
            <div class="fs-6 fw-bolder mb-4 widget-title">{{ courseName }}</div>
            <ul>
                <li>
                    <a
                        (click)="redirectToSampleVideo()"
                        class="align-items-center cursor-pointer activate-route"
                    >
                        {{ getSampleVideoName() }}
                        ({{ courseDetails?.videosCount ?? 0 }})
                    </a>
                </li>
                <li *ngIf="canShowSampleMaterials()">
                    <a
                        [routerLink]="['/', courseName, 'sample-materials']"
                        class="align-items-center"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{
                            exact: true,
                        }"
                    >
                        Sample Materials ({{ courseDetails?.materialsCount ?? 0 }})
                    </a>
                </li>
                <li *ngIf="canShowSampleTests()">
                    <a
                        (click)="redirectToSampleTest()"
                        class="align-items-center cursor-pointer"
                    >
                        Sample Test ({{ courseDetails?.examsCount ?? 0 }})
                    </a>
                </li>
                <li *ngIf="canShowPrevYearQP()">
                    <a
                        [routerLink]="['/', courseName, 'previous-year-qp']"
                        class="align-items-center"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{
                            exact: true,
                        }"
                    >
                        Previous Year QP({{ courseDetails?.preYearCount ?? 0 }})
                    </a>
                </li>
                <li>
                    <a
                        (click)="redirectToSyllabus()"
                        class="align-items-center cursor-pointer"
                    >
                        {{ getSyllabusName() }}
                        ({{ courseDetails?.syllabusCount ?? 0 }})
                    </a>
                </li>
            </ul>
        </div>
        <div class="widget widget_odemy_posts_thumb fs-6">
            <div class="fs-6 fw-bolder mb-4 widget-title">Related Courses</div>
            <div
                *ngFor="let course of courseDetails?.relatedCourses"
                class="fw-bold item"
            >
                <div *ngIf="course.category?.name" class="info">
                    <span>{{ course.category.name }}</span>
                </div>
                <div class="fw-bold fs-6">
                    <a
                        (click)="
                            redirectToCourse({
                                categoryName,
                                courseName: course.name,
                            })
                        "
                        class="align-items-center cursor-pointer"
                    >
                        {{ course.name }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
