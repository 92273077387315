import { Component, OnInit } from '@angular/core';
import { MyApiService } from '../../my-api.service';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/app/environment';

@Component({
    selector: 'app-sample-test',
    templateUrl: './sample-test.component.html',
    styleUrl: './sample-test.component.scss',
    standalone: false,
})
export class SampleTestComponent implements OnInit {
    totalItems: number = 0;
    itemsPerPage: number = 15;
    categoryName: string = '';
    currentPage: number = 1;
    totalPages: number = 0;
    pages: number[] = [];
    _album = [];
    courseName: string;
    courseDetails: {
        examsCount: 0;
        materialsCount: 0;
        videosCount: 0;
        syllabusCount: 0;
        preYearCount: 0;
        relatedCourses: {
            _id: string;
            name: string;
            category: { _id: string; name: string };
        }[];
    };
    isVisible: any = false;
    group;
    page;
    pageName;
    groupRoute;

    constructor(
        private route: ActivatedRoute,
        private myApiService: MyApiService,
        private router: Router,
        private titleService: Title,
        private metaService: Meta,
    ) {}

    ngOnInit(): void {
        const {
            group = 'Course',
            groupRoute = 'course',
            page = 'sample-test',
            pageName = 'Sample Tests',
        } = history.state.data ?? {};
        this.group = group;
        this.groupRoute = groupRoute;
        this.page = group === 'Course' ? 'sample-test' : page;
        this.pageName = group === 'Course' ? 'Sample Tests' : pageName;

        this.route.paramMap.subscribe((params) => {
            this.courseName = params.get('courseName');
            this.categoryName = params.get('categoryName');
            if (this.courseName) {
                this.getCourseDetails();
                this.loadPageData(this.currentPage);

                this.titleService.setTitle(`${this.courseName} - Sample Test`);

                // Set the meta description for the page
                this.metaService.updateTag({
                    name: 'description',
                    content: `${this.courseName} Sample Test`,
                });

                // You can also add other meta tags like keywords, author, etc.
                this.metaService.updateTag({
                    name: 'keywords',
                    content: 'sample-test, angular, seo, website',
                });
            }
        });
    }

    private getCourseDetails() {
        // this.courseDetails = undefined;
        this.myApiService
            .getCourseDetails(this.courseName, this.groupRoute)
            .subscribe(({ data = {} }) => {
                this.courseDetails = data;
            });
    }

    loadPageData(page: number): void {
        let api = this.myApiService.postSampleExamData.bind(this.myApiService);
        if (this.group === 'Test Batch Series') {
            if (this.page === 'sample-test') {
                api = this.myApiService.getTestBatchSeriesSampleTest.bind(
                    this.myApiService,
                );
            }
        }

        api({
            courseNames: [this.route.snapshot.paramMap.get('courseName')],
            pageNo: page,
            limit: this.itemsPerPage,
        }).subscribe((res: any) => {
            if (res?.error) {
                throw res.error;
            }
            if (res?.data) {
                if (res.data.list.length) {
                    this._album = res.data.list.map((e: any) => ({
                        name: e.subject ? e.subject.name : e.name.split('-')[0],
                        documents: [
                            {
                                title: e.name,
                                url: e.url,
                                ...(e.attachment && {
                                    image:
                                        environment.APP_URL + e.attachment.path,
                                }),
                            },
                        ],
                    }));

                    this.totalItems = res.data.count;
                    this.totalPages = Math.ceil(
                        this.totalItems / this.itemsPerPage,
                    );
                    if (this.totalPages > 1) {
                        this.updateVisiblePages();
                    }
                }
            }
        });
    }
    visiblePages: number[] = [];
    updateVisiblePages(): void {
        const maxVisiblePages = 5;
        let startPage = Math.max(
            1,
            this.currentPage - Math.floor(maxVisiblePages / 2),
        );
        let endPage = Math.min(
            this.totalPages,
            startPage + maxVisiblePages - 1,
        );

        // Adjust startPage if we're near the end of the totalPages
        if (
            endPage - startPage + 1 < maxVisiblePages &&
            endPage === this.totalPages
        ) {
            startPage = Math.max(1, this.totalPages - maxVisiblePages + 1);
        }

        this.visiblePages = Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i,
        );
    }
    openExam(url: string) {
        window.open(url, '_blank');
    }

    onPageChange(pageNumber: number): void {
        this.currentPage = pageNumber;
        this.loadPageData(this.currentPage);
    }

    redirectToSampleVideo() {
        this.myApiService.redirectToSampleVideo({
            group: this.group,
            groupRoute: this.groupRoute,
            page: this.page,
            pageName: this.pageName,
            categoryName: this.categoryName,
            courseName: this.courseName,
        });
    }

    redirectToSampleTest() {
        this.myApiService.redirectToSampleTest({
            group: this.group,
            groupRoute: this.groupRoute,
            page: this.page,
            pageName: this.pageName,
            categoryName: this.categoryName,
            courseName: this.courseName,
        });
    }

    redirectToSyllabus() {
        this.myApiService.redirectToSyllabus({
            group: this.group,
            groupRoute: this.groupRoute,
            page: this.page,
            pageName: this.pageName,
            categoryName: this.categoryName,
            courseName: this.courseName,
        });
    }

    canShowSampleMaterials() {
        return this.myApiService.canShowSampleMaterials({ group: this.group });
    }

    canShowSampleTests() {
        return this.myApiService.canShowSampleTests({ group: this.group });
    }

    canShowPrevYearQP() {
        return this.myApiService.canShowPrevYearQP({ group: this.group });
    }

    getSampleVideoName() {
        return this.myApiService.getSampleVideoName({ group: this.group });
    }

    getSyllabusName() {
        return this.myApiService.getSyllabusName({ group: this.group });
    }

    redirectToCourse({
        categoryName,
        courseName,
    }: {
        categoryName: string;
        courseName: string;
    }) {
        const currentPage = this.myApiService.getFullPageUrl();
        this.myApiService.redirectToSampleTest({
            group: this.group,
            groupRoute: this.groupRoute,
            page: this.page,
            pageName: this.pageName,
            categoryName,
            courseName,
        });
        if (currentPage !== this.myApiService.getFullPageUrl()) {
            this.myApiService.refreshPage();
        }
    }
}
