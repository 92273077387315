import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-kindergarten-about',
    templateUrl: './kindergarten-about.component.html',
    styleUrls: ['./kindergarten-about.component.scss'],
    standalone: false
})
export class KindergartenAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
