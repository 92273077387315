import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-health-experience',
    templateUrl: './health-experience.component.html',
    styleUrls: ['./health-experience.component.scss'],
    standalone: false
})
export class HealthExperienceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
