import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-yoga-about',
    templateUrl: './yoga-about.component.html',
    styleUrls: ['./yoga-about.component.scss'],
    standalone: false
})
export class YogaAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
