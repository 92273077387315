import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { IResp, ILatestUpdate } from './home/home.interface';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class MyApiService {
    private sliderApiUrl = `${environment.APP_URL}/api/futureVision/website/home/slider-image`; // Replace with your API URL
    private popularCourseApiUrl = `${environment.APP_URL}/api/futureVision/website/home/popular-course`;
    private achiversApiUrl = `${environment.APP_URL}/api/futureVision/website/home/top-achiever`;
    private testiMonialsApiUrl = `${environment.APP_URL}/api/futureVision/website/course/student-testimonial/list`;
    private contactFromApiUrl = `${environment.APP_URL}/api/futureVision/website/form-data/contact-form`;
    private sampleExamApiUrl = `${environment.APP_URL}/api/futureVision/website/course/sample-exam/list`;
    private sampleTestForTestBatchSeries = `${environment.APP_URL}/api/futureVision/website/test-batch-series/sample-test/list`;
    private courseCategory = `${environment.APP_URL}/api/futureVision/website/course/category`;
    constructor(
        private http: HttpClient,
        private router: Router,
    ) {}
    // GET request
    getCoursesData(): Observable<any> {
        return this.http.get<any>(
            `${environment.APP_URL}/api/futureVision/website/course/category/list`,
        );
    }

    getGalleryInfrastructureData(): Observable<any> {
        return this.http.get<any>(
            `${environment.APP_URL}/api/futureVision/website/gallery/infrastructure`,
        );
    }

    getAchieverData(data: any): Observable<any> {
        return this.http.post<any>(
            `${environment.APP_URL}/api/futureVision/website/gallery/achiever/list`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getCoursesCategoryData(id: any): Observable<any> {
        return this.http.get<any>(
            `${environment.APP_URL}/api/futureVision/website/course/category?categoryId=${id}`,
        );
    }

    getCoursesCategoryDataByName(name: string): Observable<any> {
        return this.http.get<any>(
            `${environment.APP_URL}/api/futureVision/website/course/category/by-name?name=${name}`,
        );
    }

    getData(id: any): Observable<any> {
        if (id) {
            return this.http.get<any>(
                `${this.courseCategory}?categoryId=${id}`,
            );
        } else {
            return this.http.get<any>(`${this.sliderApiUrl}`);
        }
    }

    getPopularCourseData(): Observable<any> {
        return this.http.get<any>(`${this.popularCourseApiUrl}`);
    }

    getAchieversData(data): Observable<any> {
        return this.http.post<any>(`${this.achiversApiUrl}`, data);
    }

    getStudentTestimonialsData(data): Observable<any> {
        return this.http.post<any>(
            `${this.testiMonialsApiUrl}?pageNo=${data.pageNo}&limit=${data.limit}`,
            data,
        );
    }

    // POST request
    postData(data: any): Observable<any> {
        return this.http.post<any>(`${this.sliderApiUrl}/data`, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        });
    }

    // POST request
    postContactData(data: any): Observable<any> {
        return this.http.post<any>(`${this.contactFromApiUrl}`, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        });
    }

    // POST request
    postSampleExamData(data: any): Observable<any> {
        return this.http.post<any>(
            `${this.sampleExamApiUrl}?pageNo=${data.pageNo}&limit=${data.limit}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getSampleVideoData(data: any): Observable<any> {
        return this.http.post<any>(
            `${
                environment.APP_URL
            }/api/futureVision/website/course/sample-video?canPaginate=${false}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }
    getSampleMaterialData(data: any): Observable<any> {
        return this.http.post<any>(
            `${environment.APP_URL}/api/futureVision/website/course/sample-material?pageNo=${data.pageNo}&limit=${data.limit}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }
    getPreviousYearData(data: any): Observable<any> {
        return this.http.post<any>(
            `${environment.APP_URL}/api/futureVision/website/course/prev-year-qp/list?pageNo=1&limit=10&search=`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getSyallbusData(data: any): Observable<any> {
        return this.http.post<any>(
            `${environment.APP_URL}/api/futureVision/website/course/syllabus/list?pageNo=1&limit=10&search=`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getCourseDetails(courseName: string, group = 'course'): Observable<any> {
        return this.http.post<any>(
            `${environment.APP_URL}/api/futureVision/website/${group}/detail`,
            { courseName },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getAllLatestUpdates({
        categoryIds,
    }: { categoryIds?: string[] } = {}): Observable<
        IResp<{ list: ILatestUpdate[] }>
    > {
        return this.http.post<IResp<{ list: ILatestUpdate[] }>>(
            `${
                environment.APP_URL
            }/api/futureVision/website/home/latest-update/list?canPaginate=${false}`,
            { categoryIds },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getTestBatchSeriesExplanationVideo(data: any): Observable<any> {
        return this.http.post<any>(
            `${
                environment.APP_URL
            }/api/futureVision/website/test-batch-series/explanation-video/sample-video?canPaginate=${false}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getMaterialsExplanationVideo(data: any): Observable<any> {
        return this.http.post<any>(
            `${
                environment.APP_URL
            }/api/futureVision/website/material/explanation-video/sample-video?canPaginate=${false}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getTestBatchSeriesTestSchedules(data: any): Observable<any> {
        return this.http.post<any>(
            `${
                environment.APP_URL
            }/api/futureVision/website/test-batch-series/test-schedule/list?canPaginate=${false}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getMaterialsSampleMaterials(data: any): Observable<any> {
        return this.http.post<any>(
            `${
                environment.APP_URL
            }/api/futureVision/website/material/sample-material/list?canPaginate=${false}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }
    getTestBatchSeriesSampleTest(data: any): Observable<any> {
        return this.http.post<any>(
            `${this.sampleTestForTestBatchSeries}?pageNo=${data.pageNo}&limit=${data.limit}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    getWebsiteData(): Observable<IResp<{ termsAndConditions: string }>> {
        return this.http.get<IResp<{ termsAndConditions: string }>>(
            `${environment.APP_URL}/api/futureVision/setting/website/getValue`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            },
        );
    }

    // routes

    redirectToSampleVideo({
        group,
        groupRoute,
        page,
        pageName,
        categoryName,
        courseName,
        route = 'sample-classes',
    }) {
        let data = {
            group: 'Course',
            groupRoute: 'course',
            page: 'sample-classes',
            pageName: 'Sample Classes',
        };
        if (['Test Batch Series', 'Materials'].includes(group)) {
            route = 'explanation-video';
            data = {
                group: group,
                groupRoute: groupRoute,
                page: route,
                pageName: 'Explanation Video',
            };
        }

        this.router.navigate(
            [...(categoryName ? [categoryName] : []), courseName, route],
            {
                state: { data },
            },
        );
    }

    redirectToSampleTest({
        group,
        groupRoute,
        page,
        pageName,
        categoryName,
        courseName,
        route = 'sample-test',
    }) {
        let data = {
            group: 'Course',
            groupRoute: 'course',
            page: 'sample-test',
            pageName: 'Sample Tests',
        };
        if (group === 'Test Batch Series') {
            data = {
                group: group,
                groupRoute: groupRoute,
                page: route,
                pageName: 'Sample Test',
            };
        }
        this.router.navigate(
            [...(categoryName ? [categoryName] : []), courseName, route],
            {
                state: { data },
            },
        );
    }

    redirectToSyllabus({
        group,
        groupRoute,
        page,
        pageName,
        categoryName,
        courseName,
    }) {
        let route = 'syllabus';
        let subRoute = '';
        let data = {
            group: 'Course',
            groupRoute: 'course',
            page: 'syllabus',
            pageName: 'Syllabus',
        };
        if (group === 'Test Batch Series') {
            route = 'test-schedule';
            data = {
                group: group,
                groupRoute: groupRoute,
                page: route,
                pageName: 'Test Schedules',
            };
        }
        if (group === 'Materials') {
            subRoute = 'material';
            route = 'sample-materials';
            data = {
                group: group,
                groupRoute: groupRoute,
                page: route,
                pageName: 'Sample Materials',
            };
        }

        this.router.navigate(
            [
                ...(categoryName ? [categoryName] : []),
                courseName,
                ...(subRoute ? [subRoute] : []),
                route,
            ],
            {
                state: { data },
            },
        );
    }

    canShowSampleMaterials({ group }) {
        return ['Course'].includes(group);
    }

    canShowSampleTests({ group }) {
        return ['Course', 'Test Batch Series'].includes(group);
    }

    canShowPrevYearQP({ group }) {
        return ['Course'].includes(group);
    }

    getSampleVideoName({ group }) {
        return group === 'Course' ? 'Sample Class' : 'Explanation Video';
    }

    getSyllabusName({ group }) {
        return group === 'Course'
            ? 'Syllabus'
            : group === 'Test Batch Series'
              ? 'Test Schedules'
              : 'Sample Materials';
    }

    refreshPage(): void {
        window.location.reload(); // Reloads the entire page
    }
    getFullPageUrl(): string {
        return window.location.href;
    }
}
