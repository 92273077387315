import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-health-events',
    templateUrl: './health-events.component.html',
    styleUrls: ['./health-events.component.scss'],
    standalone: false
})
export class HealthEventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
