import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-online-training-banner',
    templateUrl: './online-training-banner.component.html',
    styleUrls: ['./online-training-banner.component.scss'],
    standalone: false
})
export class OnlineTrainingBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
