import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-gym-courses',
    templateUrl: './gym-courses.component.html',
    styleUrls: ['./gym-courses.component.scss'],
    standalone: false
})
export class GymCoursesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
