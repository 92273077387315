import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-premium-access',
    templateUrl: './premium-access.component.html',
    styleUrls: ['./premium-access.component.scss'],
    standalone: false
})
export class PremiumAccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
