import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog-style-one',
    templateUrl: './blog-style-one.component.html',
    styleUrls: ['./blog-style-one.component.scss'],
    standalone: false
})
export class BlogStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
