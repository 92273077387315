import {Component, OnDestroy, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-about-four',
    templateUrl: './about-four.component.html',
    styleUrl: './about-four.component.scss',
    standalone: false
})
export class AboutFourComponent implements OnInit, OnDestroy {
  constructor(private titleService: Title, private metaService: Meta) { }

    ngOnInit(): void {
        // Set the title for the page
        this.titleService.setTitle('About us – Future Vision Study Centre');

        // Set the meta description for the page
        this.metaService.updateTag({ name: 'description', content: 'Mission Statement: To empower students with high-quality education and personalized coaching, enabling them to excel in NEET, TNPSC, and TRB exams.' });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({ name: 'keywords', content: 'about, angular, seo, website' });
    }
    ngOnDestroy() {
        // Set the title for the page
        this.titleService.setTitle('Future Vision Study Centre');

        // Set the meta description for the page
        this.metaService.updateTag({ name: 'description', content: 'Welcome to Future Vision Study Centre' });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({ name: 'keywords', content: 'future vision, angular, seo, website' });
    }

  // Tabs
  currentTab = 'tab1';
  currentTab2 = 'tab-pane1';
  currentTab3 = 'tab-pane-pane1';
  switchTab(event: MouseEvent, tab: string) {
      event.preventDefault();
      this.currentTab = tab;
  }
  switchTab2(event: MouseEvent, tabPane: string) {
      event.preventDefault();
      this.currentTab2 = tabPane;
  }
  switchTab3(event: MouseEvent, tabPane1: string) {
      event.preventDefault();
      this.currentTab3 = tabPane1;
  }
}
