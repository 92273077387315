<div class="mt-5 p-2 row">
    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <div class="section-title">
            <h2>Our Coachings</h2>
        </div>
        <div class="container">
            <div class="row">
                <!-- Iterate through courses and display each card -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" *ngFor="let item of courses">
                    <div class="card single-features-box" (click)="redirectCourseCategory(item.id, item.name)"
                        style="background-color: #0b2985">
                        <div class="row no-gutters">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <img [src]="item.path" class="card-img" />
                            </div>
                            <div class="align-items-center col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 d-flex text-start">
                                <div class="card-body">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-title mobile-title-now-show">
            <h2>Popular Courses</h2>
        </div>
    </div>

    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <app-marquee [latestUpdates]="latestUpdates"></app-marquee>
    </div>
</div>
<div class="container">
    <div class="courses-area pt-50 pb-70">
        <div class="container">
            <div class="section-title mobile-title-show">
                <h2>Popular Courses</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6" *ngFor="let item of data">
                    <div class="single-courses-item-box">
                        <div class="courses-image">
                            <span class="d-block image align-items-center">
                                <img [src]="item.imgPath" [alt]="item.name" />
                            </span>
                        </div>
                        <div class="courses-content">
                            <h3 style="font-size: 16px">
                                <a>{{ item.name }}</a>
                            </h3>
                            <br />
                            <div class="courses-box-footer" style="text-align: left">
                                <div>
                                    <i class="flaticon-agenda" style="color: #fe4a55"></i>&nbsp;&nbsp;
                                    <a [routerLink]="[
                                            item.name,
                                            'sample-classes'
                                        ]" class="align-items-center" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">
                                        Sample Classes
                                    </a>
                                </div>

                                <div>
                                    <i class="flaticon-people" style="color: #fe4a55"></i>&nbsp;&nbsp;
                                    <a [routerLink]="[
                                            item.name,
                                            'sample-materials'
                                        ]" class="align-items-center" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">
                                        Sample Materials
                                    </a>
                                </div>
                                <div>
                                    <i class="flaticon-calendar" style="color: #fe4a55"></i>&nbsp;&nbsp;
                                    <a [routerLink]="[
                                            item.name,
                                            'sample-test'
                                        ]" class="align-items-center" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">
                                        Sample Test
                                    </a>

                                </div>
                                <div>
                                    <i class="flaticon-distance-learning" style="color: #fe4a55"></i>&nbsp;&nbsp;
                                    <a [routerLink]="[
                                            item.name,
                                            'previous-year-qp'
                                        ]" class="align-items-center" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">
                                        Previous year QP
                                    </a>

                                </div>
                                <div>
                                    <i class="flaticon-web" style="color: #fe4a55"></i>&nbsp;&nbsp;
                                    <a [routerLink]="[
                                            item.name,
                                            'syllabus'
                                        ]" class="align-items-center" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">
                                        Syllabus
                                    </a>

                                </div>
                                <div>
                                    <i class="flaticon-credit-card-1" style="color: #fe4a55"></i>&nbsp;&nbsp;
                                    <a (click)="openModal(item.name,item.fees)" class="align-items-center">
                                        Fee Structure
                                    </a>

                                </div>
                            </div>
                            <!-- <app-course-fee [fees]="item.fees"></app-course-fee> -->
                            <br />
                            <a (click)="redirectToEnroll(item._id)"
                                class="default-btn align-items-center cursor-pointer" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">
                                Join Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" [ngClass]="{ 'modal-open': isModalVisible }">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{courseName}}</h5>

            </div>
            <div class="modal-body">

                <div class="row text-center">


                    <div *ngFor="let fee of fees" class="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 fw-bold">
                        <div>
                            {{ fee.type === "Online" ? "Online" : "Direct" }}
                            Class Fee
                        </div>
                        <div class="text-danger">INR {{ fee.fee }}</div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>

            </div>
        </div>
    </div>
</div>
