import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-kitchen-coaching-events',
    templateUrl: './kitchen-coaching-events.component.html',
    styleUrls: ['./kitchen-coaching-events.component.scss'],
    standalone: false
})
export class KitchenCoachingEventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
